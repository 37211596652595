'use client';

import { createContext } from 'react';
import { TermsDocumentType } from '../types/terms-document';

export type TermsContext = {
  isReady: boolean;
  openDocumentForReading: (document: TermsDocumentType, campus?: string) => void;
  openDocumentsForConsent: (documents: TermsDocumentType[], signerId: string, campus?: string) => void;
};
const defaultContextProps: TermsContext = {
  isReady: false,
  openDocumentForReading: () => undefined,
  openDocumentsForConsent: () => undefined,
};
export const TermsContext = createContext<TermsContext>(defaultContextProps);
