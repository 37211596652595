import { NextResponse } from 'next/server';
import getI18nConfiguration from '../configuration';

/*
 * WARNING: this function mutates its res argument
 */
export function setCookieOnResponse(res: NextResponse, locale: string): void {
  const configuration = getI18nConfiguration();
  res.cookies.set(configuration.cookie.name, locale, configuration.cookie.options);
}
