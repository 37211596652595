export { useClientTranslations } from './client/use-translation.hook';
export { serverTranslations } from './server/server-translations';
export { supportedTranslations } from './services/i18n.services';
export { localeFromRequest } from './server/locale/locale-from-request';
export { setCookieOnResponse } from './server/set-cookie-on-response';
export { TranslationsProvider } from './client/translations.provider';
export { type PropsWithLocale } from './locales/props-with-locale';

import getI18nConfiguration from './configuration';

export { i18nConfigSchema } from './configuration';

export { getI18nConfiguration };
