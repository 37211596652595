export enum AuthAction {
  Login = 'login',
  Logout = 'logout',
}

const pathByAction: Record<AuthAction, (locale?: string) => string> = {
  [AuthAction.Login]: (locale) => (locale ? `/${locale}/login` : '/login'),
  [AuthAction.Logout]: (locale) => (locale ? `/${locale}/logout` : '/logout'),
};

export function uriToAuth({
  action,
  authAppUrl,
  redirectUri,
  locale,
  isLoginWithGoogleEnabled,
}: {
  action: AuthAction;
  authAppUrl: string;
  redirectUri: string;
  locale?: string;
  isLoginWithGoogleEnabled?: boolean;
}): string {
  const loginUrl = new URL(authAppUrl);
  loginUrl.pathname = pathByAction[action](locale);
  loginUrl.searchParams.append('redirectUri', redirectUri);
  if (isLoginWithGoogleEnabled) {
    loginUrl.searchParams.append('gSSO', '');
  }
  return loginUrl.toString();
}
