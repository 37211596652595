import { createInstance, i18n, Resource } from 'i18next';
import { initReactI18next } from 'react-i18next/initReactI18next';
import getI18nConfiguration from '../configuration';
import { getBackendConfiguration, supportedLocales } from '../services/i18n.services';

/*
 * WARNING: this function may mute its i18nInstance argument
 */
export async function serverTranslations(
  locale: string,
  namespaces: string[],
  i18nInstance?: i18n,
  resources?: Resource,
): Promise<{ i18n: i18n; resources: Resource; t: i18n['t'] }> {
  const configuration = getI18nConfiguration();
  // Create a new i18next instance if none is provided
  const instance = i18nInstance || createInstance();

  instance.use(initReactI18next);

  // Get the backend configuration from the I18nProvider
  const backendConfiguration = getBackendConfiguration();

  if (!resources) {
    instance.use(backendConfiguration.module);
  }

  // Configure the instance for server side
  await instance.init({
    lng: locale,
    resources,
    debug: configuration.debug,
    fallbackLng: 'en',
    fallbackNS: namespaces[0],
    defaultNS: namespaces[0],
    ns: namespaces,
    preload: resources ? [] : supportedLocales,
    ...backendConfiguration.options,
  });

  return {
    i18n: instance,
    resources: instance.services.resourceStore.data,
    t: instance.t,
  };
}
