'use client';

import { PropsWithChildren, useEffect } from 'react';
import { TermsDocumentType } from '../types/terms-document';
import { useTerms } from './terms.hook';

export const RequireConsentForDocuments = ({
  children,
  documents,
  userId,
  campus,
}: PropsWithChildren<{
  documents: TermsDocumentType[];
  campus?: string;
  userId: string;
}>): PropsWithChildren['children'] => {
  const terms = useTerms();

  useEffect(() => {
    if (!terms.isReady) return;
    terms.openDocumentsForConsent(documents, userId, campus);
  }, [campus, documents, userId, terms]);

  return children;
};
