'use client';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { CopyIcon, MessageCircleQuestion } from 'lucide-react';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTrigger,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Separator,
  SidebarMenuButton,
  SidebarMenuItem,
  useToast,
} from '@design-system';
import { Path } from '@apply/server-actions/results/current-applicant.result';

export function SupportLink({ disabled, activePaths }: { disabled: boolean; activePaths: Path[] }): React.JSX.Element {
  const { t } = useTranslation('apply');
  const { toast } = useToast();
  const [selectedPath, setSelectedPath] = React.useState<Path>(activePaths[0]);

  const handleCopy = (): void => {
    navigator.clipboard.writeText(selectedPath.campus.supportEmailAddress).then(() =>
      toast({
        variant: 'success',
        title: t('support.dialog.cta.copy.success.title'),
        duration: 2000,
      }),
    );
  };

  return (
    <SidebarMenuItem key="support">
      <Dialog>
        <DialogTrigger asChild>
          <SidebarMenuButton disabled={disabled}>
            {MessageCircleQuestion ? <MessageCircleQuestion /> : null}
            {t('support.cta')}
          </SidebarMenuButton>
        </DialogTrigger>
        <DialogContent>
          {activePaths.length > 1 && (
            <div className="gap-md flex flex-col">
              <p className="text-secondary">{t('support.dialog.form.instructions')}</p>
              <div className="flex flex-col sm:col-span-2 md:col-span-1">
                <Label className="mb-xs">{t('support.dialog.form.fields.path.label')} *</Label>
                <div className="max-w-full">
                  <Select
                    value={selectedPath.id}
                    onValueChange={(value) => setSelectedPath(activePaths.find((path) => path.id === value)!)}
                  >
                    <SelectTrigger>
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      {activePaths.map((path) => (
                        <SelectItem value={path.id} key={path.id}>
                          {`${path.campus.name} - ${t(`path.types.${path.pathType}`)}`}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
              <Separator />
            </div>
          )}
          <div className="gap-md flex flex-col">
            <p>{t('support.dialog.instructions')}</p>
            <div className="flex items-center space-x-2">
              <p className="w-full">{selectedPath.campus.supportEmailAddress}</p>
              <Button RightIcon={CopyIcon} srOnly="Copy" onClick={handleCopy} />
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button type="button" variant="secondary" className="w-full">
                {t('support.dialog.cta.close')}
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </SidebarMenuItem>
  );
}
