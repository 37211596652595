export const dynamic = 'force-dynamic';

import resourcesToBackend from 'i18next-resources-to-backend';
import I18NextHttpBackend from 'i18next-http-backend';
import { Module } from 'i18next';
import getI18nConfiguration from '../configuration';

export type SupportedTranslation = { key: string; name: string; isDefault: boolean; icon?: string };

export const supportedTranslations: SupportedTranslation[] = getI18nConfiguration().languages;

export const supportedLocales: SupportedTranslation['key'][] = supportedTranslations.map(
  (translation) => translation.key,
);

export const getBackendConfiguration = (): { module: Module; options: Record<string, unknown> } => {
  const { strategy, cdnUri } = getI18nConfiguration();

  if (strategy === 'cdn')
    return {
      module: I18NextHttpBackend,
      options: { backend: { loadPath: `${cdnUri}/{{lng}}/{{ns}}` } },
    };

  return {
    module: resourcesToBackend(
      (language: string, namespace: string) => import(`../locales/${language}/${namespace}.json`),
    ),
    options: {},
  };
};
