'use client';

import React from 'react';
import { useParams } from 'next/navigation';
import Link from 'next/link';
import { Logo } from '@design-system';
import { Path } from '@apply/server-actions/results/current-applicant.result';

export function ApplyLogoWithPaths({ paths, locale }: { paths: Path[]; locale: string }): React.JSX.Element {
  const params = useParams();
  const campusCity = paths.find((path) => path.id === params?.pathId)?.campus.city;
  return (
    <Link href={`/${locale}`}>
      <Logo campus={campusCity} height="100%" />
    </Link>
  );
}
