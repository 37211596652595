'use client';
import React from 'react';
import { useSession } from 'next-auth/react';
import { RedirectToLogin } from './redirect-to-login';

export type AuthenticationGuardProps = {
  locale?: string;
  isLoginWithGoogleEnabled?: boolean;
  children: React.ReactElement;
  authAppUrl: string;
};

export function AuthenticationGuard({
  children,
  locale,
  authAppUrl,
  isLoginWithGoogleEnabled = false,
}: AuthenticationGuardProps): React.JSX.Element {
  const { data: session } = useSession();
  const shouldRedirect = !session || !session.isValid;

  if (shouldRedirect) {
    return (
      <RedirectToLogin locale={locale} authAppUrl={authAppUrl} isLoginWithGoogleEnabled={isLoginWithGoogleEnabled} />
    );
  }

  return children;
}
