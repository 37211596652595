'use client';

import React from 'react';
import { useParams } from 'next/navigation';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { SidebarMenuButton, SidebarMenuItem } from '@design-system';
import { Path } from '@apply/server-actions/results/current-applicant.result';

export function PathLink({ locale, path }: { path: Path; locale: string }): React.JSX.Element {
  const { t } = useTranslation('apply');
  const params = useParams();
  const href = `/${locale}/paths/${path.id}`;
  return (
    <SidebarMenuItem key={path.id}>
      <SidebarMenuButton asChild isActive={params?.pathId === path.id}>
        <Link href={href}>
          {path.campus.name} - {t(`path.types.${path.pathType}`)}
        </Link>
      </SidebarMenuButton>
    </SidebarMenuItem>
  );
}
