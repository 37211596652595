'use client';

import type { TermsDocument } from '../types/terms-document';
import { Configuration } from '../configuration';

export class AxeptioService {
  constructor(
    webhooks: { onSdkReady: () => void },
    private readonly configuration: Configuration,
  ) {
    if (!configuration.isEnabled || typeof window === 'undefined') return;

    (window as any).axeptioSettings = {
      ...(window as any).axeptioSettings,
      clientId: configuration.clientId,
      userCookiesDomain: this.configuration.userCookiesDomain,
    };

    (window as any)._axcb = ((window as any)._axcb ?? []).concat((axeptio: any): void => {
      axeptio.on('ready', () => webhooks.onSdkReady());
    });
  }

  private _getContractNameFromDocument(document: TermsDocument): string {
    const contractsNames =
      (window as any)?.axeptioSDK?.config.contractsV2.map(({ name }: { name: string }) => name) ?? [];
    const { type, locale, campus } = document;

    if (contractsNames.includes(`${type}-${campus}-${locale}`)) return `${type}-${campus}-${locale}`;

    if (contractsNames.includes(`${type}-${campus}`)) return `${type}-${campus}`;

    if (contractsNames.includes(`${type}-${locale}`)) return `${type}-${locale}`;

    if (contractsNames.includes(type)) return type;

    throw new Error('Contract not found');
  }

  public openDocumentForReading(document: TermsDocument): void {
    if (!this.configuration.isEnabled || typeof window === 'undefined') return;

    const name = this._getContractNameFromDocument(document);

    (window as any).axeptioSDK?.openContract(name, { readOnly: true });
  }

  public openDocumentsForConsent(documents: TermsDocument[], signerId: string): void {
    if (!this.configuration.isEnabled || typeof window === 'undefined') return;

    const contracts = documents.map((document) => ({
      name: this._getContractNameFromDocument(document),
      readOnly: false,
      consentFor: signerId,
    }));
    (window as any).axeptioSDK?.checkContracts(contracts);
  }

  get isReady(): boolean {
    if (!this.configuration.isEnabled || typeof window === 'undefined') return false;
    return (window as any).axeptioSDK?.isReady || false;
  }
}
