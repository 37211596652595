import * as z from 'zod';

const strategy = z.enum(['cdn', 'static']);
const schemaUsingCdnStrategy = z.object({
  strategy: z.literal(strategy.enum.cdn),
  languages: z.array(z.object({ key: z.string().min(1), name: z.string().min(1), isDefault: z.boolean() })).min(1),
  cdnUri: z.string().min(1).url(),
  debug: z.boolean(),
});
const schemaUsingStaticStrategy = z.object({
  strategy: z.literal(strategy.enum.static),
  languages: z.array(z.object({ key: z.string().min(1), name: z.string().min(1), isDefault: z.boolean() })).min(1),
  cdnUri: z.string().optional(),
  debug: z.boolean(),
});

export const i18nConfigSchema = z.discriminatedUnion('strategy', [schemaUsingCdnStrategy, schemaUsingStaticStrategy]);

export type Configuration = z.infer<typeof i18nConfigSchema>;

const getI18nConfiguration = (): typeof configuration => {
  const configuration = {
    cdnUri: process.env.I18N_CDN_URI,
    strategy: process.env.I18N_STRATEGY,
    languages: JSON.parse(process.env.I18N_LANGUAGES || '[]'),

    debug: process.env.I18N_DEBUG === 'true',
    cookie: {
      name: 'lang',
      options: {
        domain: `.${process.env.SAFE_DOMAIN}`,
        maxAge: 365 * 24 * 60 * 60 * 1000,
        httpOnly: process.env.NODE_ENV !== 'development',
        secure: true,
        sameSite: 'strict' as 'lax' | 'strict' | 'none',
      },
    },
  };

  return configuration;
};
export default getI18nConfiguration;
