'use client';

import { I18nextProvider } from 'react-i18next';
import { Resource, createInstance } from 'i18next';
import { serverTranslations } from '../server/server-translations';

export function TranslationsProvider({
  children,
  locale,
  namespaces,
  resources,
}: {
  children: React.ReactNode;
  locale: string;
  namespaces: string[];
  resources: Resource;
}): JSX.Element | null {
  const instance = createInstance();
  serverTranslations(locale, namespaces, instance, resources);

  return <I18nextProvider i18n={instance}>{children}</I18nextProvider>;
}
