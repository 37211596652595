import { NextRequest } from 'next/server';
import getI18nConfiguration from '../../configuration';
import { supportedLocales, supportedTranslations } from '../../services/i18n.services';
import { MiddlewareLocale } from './middleware-locale';

export function localeFromRequest(req: NextRequest): string {
  const configuration = getI18nConfiguration();
  const localeFromPath = req.nextUrl.pathname;
  const localeFromBrowser = req.headers.get('accept-language');
  const localeFromCookie = req.cookies.get(configuration.cookie.name)?.value;
  return MiddlewareLocale.of(localeFromPath, localeFromBrowser, localeFromCookie).detectWith(
    supportedTranslations,
    supportedLocales,
  );
}
